import React from "react";
import {Container, PageHeading} from "./common";

let CenteredColumnLayout = ({children, cols = 8, containerClassName= '', colClassName = ''}) => {
    let offset = (12 - cols) / 2;

    return <Container className={containerClassName}>
        <div className={"row"}>
            <div className={`col-lg-10 offset-lg-1 col-xl-${cols} offset-xl-${offset} ${colClassName}`}>
                <div>
                    {children}
                </div>
            </div>
        </div>
    </Container>;
}

export let LoginLikeLayout = ({children}) => {
    return <CenteredColumnLayout>
        <div className="d-flex flex-column align-items-center pt-md-5">
            <div className="w-100 mt-auto" style={{maxWidth: '526px'}}>
                {children}
            </div>
        </div>
    </CenteredColumnLayout>
}

export let Padding = ({children}) => {
    return <div className="my-5 my-xl-7">
        {children}
    </div>
}

export let TitleContentLayout = ({title, children}) => {
    return <>
        <PageHeading>
            {title}
        </PageHeading>

        {children}
    </>
}

export default CenteredColumnLayout

import React from "react";
import {FormattedMessage} from "react-intl";
import {ButtonWithIconLeft} from "@commons/uinew/component/buttons/ButtonWithIcon";

export let SOCIAL_PROVIDER_POSTFIX_SO = "_so"

export let SignInOptions = ({
                                onClick,
                                postfix = "",
                                buttonTextMessageId = 'components.continue-with-social-buttons.continue-with',
                                additionalItems
                            }) => {
    return <div className={"d-flex flex-column hr gap-2"}>

        {additionalItems}

        <ButtonWithIconLeft iconUrl={"/img/icons/google.svg"} onClick={e => onClick(e, `google${postfix}`)}>
            <FormattedMessage id={buttonTextMessageId} values={{provider: "Google"}}/>
        </ButtonWithIconLeft>

        <ButtonWithIconLeft iconUrl={"/img/icons/facebook.svg"} onClick={e => onClick(e, `facebook${postfix}`)}>
            <FormattedMessage id={buttonTextMessageId} values={{provider: "Facebook"}}/>
        </ButtonWithIconLeft>
    </div>
}

import * as axi from "axios";
import {loggedOut, unexpectedErrorOccurred} from "../../helpers";
import {tryLogout} from "../../../domain/redux/actions";
import {store} from "../../../shared";
import {MARKETING_PROPERTIES} from "@commons/infra/MarketingPropertiesCapturer";

let axios = axi.create({
    baseURL: window && window.CONFIG && window.CONFIG.BACKEND_BASE_URL, // unit test safety
    withCredentials: true, // TODO should not be enabled in prod?
    maxRedirects: 0
});

axios.interceptors.request.use(
    c => {
        if (c.method === 'get') {
            return c
        }


        let utmSource = getPosthogField("utm_source");
        if (utmSource) {
            c.headers['Utm-Source'] = utmSource
            c.headers['Utm-Medium'] = getPosthogField("utm_medium")
            c.headers['Utm-Campaign'] = getPosthogField("utm_campaign")
            c.headers['Utm-Term'] = getPosthogField("utm_term")
            c.headers['Utm-Content'] = getPosthogField("utm_content")
            c.headers['Fbclid'] = getPosthogField("fbclid")
        }

        return c
    }
)

let getPosthogField = (fieldName) => {
    if ('posthog' in window) {
        let value = window.posthog.get_property(fieldName)
        if (value) {
            return value
        }

        if ('sessionStorage' in window.posthog) {
            value = window.posthog.sessionStorage.get_property(fieldName)
            if (value) {
                return value
            }
        }

        if ('autocapture' in window && 'instance') {
            try {
                value = window.posthog.autocapture.instance.sessionPersistence.props[fieldName]
                if (value) {
                    return value
                }
            } catch (e) {
            }
        }
    }

    let inMemoryValue = MARKETING_PROPERTIES[fieldName];
    if (inMemoryValue) {
        return inMemoryValue
    }
}

axios.interceptors.response.use(
    res => res,
    err => {
        if (err.response) {
            if (err.response.status >= 500) {
                unexpectedErrorOccurred()
                throw err
            }

            if (err.response.status === 401) {
                loggedOut()
                store.dispatch(tryLogout())
                return Promise.reject(err)
            }

            throw err
        }

        throw err;
    }
)

export default axios;

import React from "react";

export let PrimaryAlert = ({children}) => {
    return <div className="alert d-flex alert-primary border-0 fw-500" role="alert">
        <i className="ai-circle-alert me-2"/>
        {children}
    </div>
}

export let InfoAlert = ({children}) => {
    return <div className="alert d-flex alert-info border-0 fw-500" role="alert">
        <i className="ai-circle-info me-2"/>
        {children}
    </div>
}

export let SecondaryAlert = ({children}) => {
    return <div className="alert d-flex alert-secondary border-1 fw-500" role="alert">
        <i className="ai-circle-info me-2"/>
        {children}
    </div>
}
import {InfoAlert} from "@commons/uinew/component/alerts";
import {FormProceedButton} from "@commons/uinew/component/forms";
import {PatientOnboardingLinks} from "../../../../../portal-latvia/src/patient/page/onboarding/links";
import {FormattedMessage} from "react-intl";
import React from "react";
import {useNavigate} from "react-router-dom";
import {CustomFormattedMessage} from "@commons/infra/component/CustomFormattedMessage";

export let PleaseCompleteOnboardingAlert = () => {
    let navigate = useNavigate()

    return <>
        <InfoAlert>
            <div>
                <CustomFormattedMessage id={"components.please-complete-onboarding.text"}/>
                <FormProceedButton onClick={() => navigate(PatientOnboardingLinks.onboarding.controller(true))}>
                    <FormattedMessage id={'pages.booking-reservation.continue-button'}/>
                </FormProceedButton>
            </div>
        </InfoAlert>
    </>
}
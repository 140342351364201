import React, {useEffect, useState} from "react";
import {Navigate, useNavigate, useParams} from 'react-router-dom';
import {
    formatEurPrice,
    getTimezone,
    isCancelledBooking,
    isEuropeRigaTimezone,
    TARGET_BLANK_PROPS,
    toDateTime,
    urlToData,
    useDomainTranslations,
    useTimeFormatter
} from "@commons/infra/helpers";
import {connect} from "react-redux";
import {DoctorThumbnail} from "../public/DoctorListPage";
import {Helmet} from "react-helmet";
import {toast} from "react-toastify";
import LocalizedLink from "@commons/infra/i18n/LocalizedLink";
import {FormattedMessage, useIntl} from "react-intl";
import {InfoToast} from "@commons/infra/component/Toast";
import {title} from "@commons/shared";
import {CommonPatientLinks} from "@commons/infra/links";
import {DateTime} from "luxon";
import {PromotionApi} from "@commons/api/patient/PromotionApi";
import {useDomainErrors} from "@commons/ui/hooks";
import Accordion from "react-bootstrap/Accordion";
import {TickedListItemWithoutTitle} from "@commons/ui/component/UnorderedList";
import {FormButtonsContainer, FormProceedButton} from "@commons/uinew/component/forms";
import {PublicLinks} from "../../../../../portal-latvia/src/utility/links";
import {CustomFormattedMessage} from "@commons/infra/component/CustomFormattedMessage";
import {defaultAppConfig} from "../../../../../portal-latvia/src/config";
import {PleaseCompleteOnboardingAlert} from "@commons/uinew/domaincomponent/onboarding/PleaseCompleteOnboardingAlert";
import {CenteredTextualContentLayout} from "@commons/uinew/layout/CenteredTextualContentLayout";

let ERROR_CODE__PROMO_CODE_INVALID = "PROMO_CODE_INVALID"

let AppointmentReservationPage = ({
                                      profile, // redux
                                      getAppointmentCall,
                                      showBreadcrumbs,
                                      reserveAppointmentCall,
                                      showAppointmentWillHappenOnlineWarning = true
                                  }) => {
    let doctorId = "13192f37-381f-44c6-9555-88b9d1575e04"
    let navigate = useNavigate()

    // eslint-disable-next-line no-undef
    return <>
        <Helmet>
            <meta name="robots" content="noindex,nofollow"/>
        </Helmet>
        <CenteredTextualContentLayout cols={8} paddingBottom={'lg'}>
            <AppointmentReservationPartial
                profile={profile}
                showBreadcrumbs={showBreadcrumbs}
                doctorId={doctorId}
                priceTextFactory={(price) => <>
                    {price === 0 &&
                        <FormattedMessage id={'pages.booking-reservation.free-appointment-warning'}/>}
                    {price !== 0 && formatEurPrice(price)}
                </>}
                onSuccessRedirectLinkFactory={(price, bookingId) => {
                    return price === 0
                        ? CommonPatientLinks.booking.patientInfo(bookingId)
                        : CommonPatientLinks.reservation.payment(doctorId, bookingId);
                }}
                appointmentNotAvailableRedirectLink={CommonPatientLinks.doctor.doctor(doctorId)}
                getAppointmentCall={getAppointmentCall}
                reserveAppointmentCall={reserveAppointmentCall}
                showAppointmentWillHappenOnlineWarning={showAppointmentWillHappenOnlineWarning}/>
        </CenteredTextualContentLayout>
    </>
}

export let AppointmentReservationPartial = ({
                                                profile,
                                                doctorId, // not needed except for urls, can be ignored
                                                showBreadcrumbs,
                                                priceTextFactory,
                                                onSuccessRedirectLinkFactory,
                                                getAppointmentCall,
                                                reserveAppointmentCall,
                                                appointmentNotAvailableRedirectLink,
                                                showAppointmentWillHappenOnlineWarning = true,
                                                isSecondOpinion = false
                                            }) => {
    let {formatMessage} = useIntl()
    let domainErrors = useDomainErrors()
    let navigate = useNavigate()
    let timeFormatter = useTimeFormatter()

    let {appointmentId} = useParams();

    let [isLoading, setIsLoading] = useState(false);
    let [appointment, setAppointment] = useState();
    let [promoCode, setPromoCode] = useState();
    let [hasPromotion, setHasPromotion] = useState();
    let [redirect, setRedirect] = useState();

    useEffect(() => {
        getAppointmentCall(appointmentId)
            .then(r => r.data)
            .then(d => setAppointment(d))
            .catch(() => {
                toast(<InfoToast text={"Sorry, this appointment is no longer available"}/>)
                navigate(appointmentNotAvailableRedirectLink, {replace: true})
            })

        PromotionApi.getPromotion(appointmentId)
            .then(r => r.data)
            .then(d => setHasPromotion(d.isAnyApplicable))
    }, [appointmentId, navigate]);

    useEffect(() => {
        if (!appointment) return

        if (appointment.currentPatientCanContinueBooking)
            navigate(CommonPatientLinks.reservation.payment(doctorId, appointment.bookingId), {replace: true})

        if (appointment.currentPatientHasBookedAlready)
            navigate(CommonPatientLinks.booking.summary(appointment.bookingId), {replace: true})
    }, [appointment, navigate, doctorId])

    let onContinue = () => {
        setIsLoading(true)
        domainErrors.clearErrors()

        reserveAppointmentCall(appointmentId, getTimezone(), promoCode)
            .then(response => setRedirect(onSuccessRedirectLinkFactory(response.data.bookingPrice, response.data.bookingId)))
            .catch(e => domainErrors.assignFromError(e))
            .finally(() => setIsLoading(false));
    }

    return appointment && <>
        <Helmet>
            <title>{title(formatMessage({id: 'pages.booking-reservation.page-title'}, {doctorName: appointment.doctor.fullName}))}</title>
        </Helmet>

        {showBreadcrumbs && <nav aria-label="breadcrumb">
            <ol className="py-1 my-2 breadcrumb">
                <li className="breadcrumb-item">
                    <LocalizedLink to={PublicLinks.doctors()}>
                        <FormattedMessage id={'components.public-header.specialists'}/>
                    </LocalizedLink>
                </li>
                <li className="breadcrumb-item">
                    <LocalizedLink
                        to={PublicLinks.doctor(appointment.doctor.slug)}>
                        {appointment.doctor.fullName}
                    </LocalizedLink>
                </li>
            </ol>
        </nav>}

        <h1 className="mb-3">
            <FormattedMessage id={'pages.booking-reservation.heading'}/>
        </h1>

        {redirect && <Navigate replace to={redirect}/>}

        {false && <AppointmentOverview
            className={'appointment-overview'}
            doctorSlug={appointment.doctor.slug}
            doctorFullName={appointment.doctor.fullName}
            thumbnailSrc={urlToData(appointment.doctor.thumbnail)}
            from={appointment.from}
            to={appointment.to}/>}

        {appointment && !appointment.isOffline && showAppointmentWillHappenOnlineWarning && <p><strong>
            <CustomFormattedMessage id={'pages.booking-payment.online-warning'}/>
        </strong></p>}

        <div className={'mb-3'}>
            {appointment.isAvailable && <>
                <p>
                    <AppointmentOverviewV3
                        from={appointment.from}
                        to={appointment.to}
                        price={appointment.price}
                        isOffline={appointment.isOffline}
                        priceTextFactory={priceTextFactory}
                    />
                </p>
                {appointment.price > 0 &&
                    <p><FormattedMessage id={'pages.booking-reservation.paid-appointment-warning'}/></p>}
                {isSecondOpinion && <p className={"mb-0"}>
                    <FormattedMessage id={"so_pages.booking-reservation.200"}/>
                </p>}
            </>}

            {profile.onboarding.completedOnboarding && hasPromotion && <div className={"mt-3"}>
                <Accordion defaultActiveKey="1">
                    <Accordion.Item eventKey="0">
                        <Accordion.Header>
                            <FormattedMessage id={"pages.booking-reservation.promo-code.title"}/>
                        </Accordion.Header>
                        <Accordion.Body>
                            <div className={"row row-cols-sm-auto g-3 align-items-center"}>
                                <div className={"col-12"}>
                                    <div className="form-floating">
                                        <input className="form-control bg-white"
                                               type="text"
                                               name="promocode"
                                               id="promocode"
                                               placeholder={<FormattedMessage
                                                   id={"pages.booking-reservation.promo-code.input-title"}/>}
                                               value={promoCode}
                                               onChange={e => setPromoCode(e.target.value)}
                                        />
                                        <label htmlFor="promocode">
                                            <FormattedMessage id={"pages.booking-reservation.promo-code.input-title"}/>
                                        </label>
                                        <div
                                            className={"invalid-feedback fs-sm " + domainErrors.blockClass(ERROR_CODE__PROMO_CODE_INVALID)}>
                                            <FormattedMessage
                                                id={"pages.booking-reservation.error.invalid-promo-code"}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            </div>}
        </div>

        {profile.onboarding.completedOnboarding && <FormButtonsContainer>
            <FormProceedButton isLoading={isLoading} disabled={!appointment.isAvailable} onClick={onContinue}>
                <FormattedMessage id={'pages.booking-reservation.continue-button'}/>
            </FormProceedButton>
        </FormButtonsContainer>}

        {!profile.onboarding.completedOnboarding && <PleaseCompleteOnboardingAlert/>}
    </>
}

export let AppointmentOverview = ({className, doctorSlug, doctorFullName, thumbnailSrc, from, to, status = null}) => {
    let timeFormatter = useTimeFormatter();
    let domainTranslations = useDomainTranslations();

    return <div className={'d-flex flex-row ' + className}>
        <div className={"ms-4 ms-md-0 me-md-4 order-2 order-md-0"}>
            <DoctorThumbnail imgSrc={thumbnailSrc}/>
        </div>

        <div className={"order-1"}>
            <div>
                <LocalizedLink className="link-normal"
                               to={PublicLinks.doctor(doctorSlug)}>{doctorFullName}</LocalizedLink>
            </div>
            <div className={"h5 mb-0"}>
                {timeFormatter.formatDate(from)}, {timeFormatter.formatTimeWithoutTz(from)} - {timeFormatter.formatTime(to)} ({toDateTime(to).diff(toDateTime(from), ['minutes']).minutes} min.)
            </div>
            <div>
                <MaybeTimeZoneNotice/>
            </div>
            {status &&
                <div>
                <span className={isCancelledBooking(status) ? "badge bg-danger" : "badge bg-success"}>
                    {domainTranslations.bookingStatus(status)}
                </span>
                </div>}
        </div>
    </div>
}

export let AppointmentOverviewV3 = ({from, to, price, priceTextFactory, isOffline}) => {
    let timeFormatter = useTimeFormatter();

    return <p>
        <TickedListItemWithoutTitle>
            <FormattedMessage id={"common.time"}/>:&nbsp;
            <span className={"fw-medium"}>
                {timeFormatter.formatShortDate(from)}, {timeFormatter.formatTimeWithoutTz(from)} - {timeFormatter.formatTime(to)}
            </span>
        </TickedListItemWithoutTitle>
        <TickedListItemWithoutTitle>
            <FormattedMessage id={"common.price"}/>:&nbsp;
            <span className={"fw-medium"}>
                {priceTextFactory ? priceTextFactory(price) : <>{formatEurPrice(price)}</>}
            </span>
        </TickedListItemWithoutTitle>
        {isOffline && <>
            <TickedListItemWithoutTitle>
                <FormattedMessage id={"common.location"}/>:&nbsp;
                <span className={"fw-medium"}>
                   <CustomFormattedMessage id={'pages.booking-payment.location'}
                                           values={{
                                               address: defaultAppConfig.clinic.address.short,
                                               addressLink: chunk => <a
                                                   href={defaultAppConfig.clinic.address.googleMapsLink} {...TARGET_BLANK_PROPS}>{chunk}</a>,
                                           }}/>
            </span>
            </TickedListItemWithoutTitle>
            <TickedListItemWithoutTitle>
                <FormattedMessage id={"pages.booking-payment.offline-warning-additional-info"}/>
            </TickedListItemWithoutTitle>
            <ul className={"list-unstyled ms-4 ps-3"}>
                <CustomFormattedMessage
                    id={"pages.booking-payment.offline-warning"}
                    values={{
                        li: chunk => <li className={"mt-1"}>{chunk}</li>
                    }}/>
            </ul>
        </>}
    </p>
}

export let MaybeTimeZoneNotice = ({className}) => {
    let [show, setShow] = useState(false);
    useEffect(() => {
        if (!isEuropeRigaTimezone()) {
            setShow(true)
        }
    }, [])

    return show && <div className={className}>
        <FormattedMessage id={'common.different-timezone-notice'}
                          values={{time: DateTime.now().toLocaleString(DateTime.TIME_24_SIMPLE)}}/>
    </div>
}

export let AppointmentOverviewV2 = ({className, doctorId, doctorFullName, thumbnailSrc, from, to, status = null}) => {
    let timeFormatter = useTimeFormatter();
    let domainTranslations = useDomainTranslations();

    return <div className={'row doctor-page-attributes pt-4'}>
        <div className="col-12 col-lg-4 mb-2">
            <label>
                <FormattedMessage id={'pages.patient-booking.date'}/>:
            </label>
            <div className={"fs-lg"}>
                {timeFormatter.formatDate(from)}, {timeFormatter.formatTime(from)} ({timeFormatter.formatDurationMinutes(from, to)})
            </div>
        </div>

        <div className="col-12 col-lg-4 mb-2">
            <label>
                <FormattedMessage id={'components.upcoming-bookings.status'}/>:
            </label>
            <div className={"fs-lg"}>
                {/*<span className={isCancelledBooking(status) ? "badge bg-danger" : "badge bg-success"}>*/}
                {domainTranslations.bookingStatus(status)}
                {/*</span>*/}
            </div>
        </div>
    </div>
}

const mapStateToProps = (state) => {
    return {
        profile: state.patient.profile
    }
};

export default connect(mapStateToProps)(AppointmentReservationPage);

import React from "react";
import {Container} from "react-bootstrap";

export let CenteredTextualContentLayout = ({children, cols = 8, breakpoint = 'lg', paddingBottom = null}) => {
    let offset = (12 - cols) / 2;

    let paddingBottomClass = ''
    if (paddingBottom === 'lg') {
        paddingBottomClass = `pb-4 pb-${breakpoint}-5`
    }

    return <Container className={`main-content mt-3 mt-lg-5 ${paddingBottomClass}`}>
        <div className={"row px-md-7"}>
            <div className={`col-lg-10 offset-lg-1 col-xl-${cols} offset-xl-${offset} gx-xl-5`}>
                <div>
                    {children}
                </div>
            </div>
        </div>
    </Container>;
}

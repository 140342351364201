import {useIntl} from "react-intl";
import en from "./translations/routes/en.json"
import lv from "./translations/routes/lv.json"
import ru from "./translations/routes/ru.json"
import {LANGUAGE_CODE_ENGLISH, LANGUAGE_CODE_LATVIAN, LANGUAGE_CODE_RUSSIAN} from "@commons/infra/i18n/LocalizedRouter";
import {removeLanguagePrefix, useCurrentRouteTemplate} from "@commons/infra/router";
import {useLocation, useParams} from "react-router-dom";
import {getStoryblokKeysMapping, STORYBLOK_MAPPING as storyBlockRoutesMap} from "@commons/infra/storyblok/data";
import {getKeyByValue} from "@commons/infra/helpers";

let routesMap = {
    [LANGUAGE_CODE_LATVIAN]: lv, [LANGUAGE_CODE_RUSSIAN]: ru, [LANGUAGE_CODE_ENGLISH]: en,
}

export let LOCALIZED_PARAM_PREFIX = "localized_"
export let LOCALIZED_PARAM_KEY_PREFIX = "route-parts."

export let useLocalizedRoutes = () => {
    let {locale, formatMessage} = useIntl()
    let currentLanguage = useIntl().locale
    let currentRouteTemplate = useCurrentRouteTemplate()
    let location = useLocation()
    let pathParams = useParams();

    let getCurrentUrlTemplateInAnotherLanguage = (targetLanguage) => {
        let currentRouteKey = findByValue(currentLanguage, currentRouteTemplate, getStoryblokKeysMapping())

        if (!currentRouteKey) {
            return replaceLanguageCodeInUrl(location, targetLanguage)
        }

        let targetRouteTemplate = findByKey(targetLanguage, currentRouteKey, getStoryblokKeysMapping())
        if (!targetRouteTemplate) return replaceLanguageCodeInUrl(location, targetLanguage)

        return replaceLanguageCodeInUrl({...location, pathname: targetRouteTemplate}, targetLanguage)
    }

    let getCurrentLocalizedParamsInAnotherLanguage = (targetLanguage) => {
        let result = {}
        Object.entries(pathParams).forEach(([k, v]) => {
            if (!k.startsWith(LOCALIZED_PARAM_PREFIX)) {
                return
            }

            let currentLanguageParamKey = findByValue(currentLanguage, v, getStoryblokKeysMapping())
            result[k] = findByKey(targetLanguage, currentLanguageParamKey, getStoryblokKeysMapping())
        })

        return result
    }

    let getTemplate = (routeMessageId) => formatMessage({id: `routes.${routeMessageId}`})

    return {
        getTemplate,

        unpack: (path) => {
            switch (typeof path) {
                case 'object':
                    return replaceUrlParams(getTemplate(path.id), path.params ?? {}, formatMessage, locale, getStoryblokKeysMapping(), true, path.queryParams)
                case 'string':
                    return path
            }
        },

        getCurrentUrl: (targetLanguage) => {
            let p1 = getCurrentUrlTemplateInAnotherLanguage(targetLanguage);

            return replaceUrlParams(p1, {...pathParams, ...getCurrentLocalizedParamsInAnotherLanguage(targetLanguage)}, formatMessage, locale, storyBlockRoutesMap, false)
        },

        getI18nKeyByParamValue: (val) => findByValue(locale, val, getStoryblokKeysMapping()).replace(LOCALIZED_PARAM_KEY_PREFIX, '')
    }
}

let findByValue = (language, template, storyBlockRoutesMap) => getKeyByValue(routesMap[language], template) || getKeyByValue(storyBlockRoutesMap[language], template) || null

let findByKey = (language, key, storyBlockRoutesMap) => {
    let a = routesMap[language][key]
    let b = storyBlockRoutesMap[language][key]

    return a || b || null
}

let replaceUrlParams = (urlTemplate, params, formatMessage, locale, storyBlockRoutesMap, localize = true, queryParams) => {
    if (!params) {
        return urlTemplate
    }
    let modifiedUrlTemplate = urlTemplate
    Object.entries(params).forEach(([k, v]) => {
        let v1 = v
        if (k.startsWith("localized_") && localize) {
            v1 = findByKey(locale, `${LOCALIZED_PARAM_KEY_PREFIX}${v}`, storyBlockRoutesMap);
        }
        modifiedUrlTemplate = modifiedUrlTemplate.replace(`:${k}`, v1)
    })

    if (queryParams) {
        let glued = Object.entries(queryParams)
            .map(([k, v]) => `${encodeURIComponent(k)}=${encodeURIComponent(v)}`)
            .join("&");
        modifiedUrlTemplate = modifiedUrlTemplate + '?' + glued
    }

    return modifiedUrlTemplate
}

let replaceLanguageCodeInUrl = (currentLocation, newLanguageCode) => {
    let pathnameWithoutLanguage = removeLanguagePrefix(currentLocation.pathname)
    let prefix = newLanguageCode === LANGUAGE_CODE_LATVIAN ? '' : `/${newLanguageCode}`

    return prefix + pathnameWithoutLanguage + currentLocation.search + currentLocation.hash;
}
import React from "react";
import LocalizedLink from "@commons/infra/i18n/LocalizedLink";

export let ButtonWithIconLeft = ({onClick, aiIcon, iconUrl, iconImgAlt, children}) => {
    return <button className={"btn btn-secondary"} onClick={onClick}>

        {aiIcon && <i className={`ai ai-${aiIcon} me-2`}/>}

        {iconUrl && <img className={"me-1"} alt={iconImgAlt} src={iconUrl} width={28} height={28}/>}

        {children}

    </button>
}

export let ButtonWithIconRight = ({onClick, aiIcon, iconUrl, iconImgAlt, children}) => {
    return <button className={"btn btn-secondary"} onClick={onClick}>

        {children}

        {aiIcon && <i className={`ai ${aiIcon} ms-2`}/>}

        {iconUrl && <img classname={"ms-2"} alt={iconImgAlt} src={iconUrl}/>}

    </button>
}

export let LinkWithIconRight = ({to, buttonStyle = 'secondary', aiIcon, iconUrl, iconImgAlt, children}) => {
    return <LocalizedLink to={to} className={`btn btn-${buttonStyle} d-block`}>

        {children}

        {aiIcon && <i className={`ai ai-${aiIcon} ms-2`}/>}

        {iconUrl && <img classname={"ms-2"} alt={iconImgAlt} src={iconUrl}/>}

    </LocalizedLink>
}

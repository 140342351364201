/**
 * Lodash fill-ins
 */

export let LodashFillIns = {
    isEmpty: obj => [Object, Array].includes((obj || {}).constructor) && !Object.entries((obj || {})).length,

    isUndefined: p => typeof p === 'undefined',

    without: (arr, el) => arr.filter(function (value) {
        return value !== el;
    }),

    isNaN: a => Number.isNaN(a),

    pullAt: (arr, idxs) =>
        idxs
            .reverse()
            .map(idx => arr.splice(idx, 1)[0])
            .reverse(),

    mapValues: (a, factory) => Object.fromEntries(
        Object.entries(a).map(([key, f]) => [key, factory(f)])
    ),

    range: (start, end, increment) => {
        // if the end is not defined...
        const isEndDef = typeof end !== 'undefined'
        // ...the first argument should be the end of the range...
        end = isEndDef ? end : start
        // ...and 0 should be the start
        start = isEndDef ? start : 0

        // if the increment is not defined, we could need a +1 or -1
        // depending on whether we are going up or down
        if (typeof increment === 'undefined') {
            increment = Math.sign(end - start)
        }

        // calculating the lenght of the array, which has always to be positive
        const length = Math.abs((end - start) / (increment || 1))

        // In order to return the right result, we need to create a new array
        // with the calculated length and fill it with the items starting from
        // the start value + the value of increment.
        const {result} = Array.from({length}).reduce(
            ({result, current}) => ({
                // append the current value to the result array
                result: [...result, current],
                // adding the increment to the current item
                // to be used in the next iteration
                current: current + increment,
            }),
            {current: start, result: []}
        )

        return result
    },

    times: (n, func = i => i) => Array.from({length: n}).map((_, i) => func(i)),

    // WARNING: This is not a drop in replacement solution and
    // it might not work for some edge cases. Test your code!
    chunk: (arr, chunkSize = 1, cache = []) => {
        const tmp = [...arr]
        if (chunkSize <= 0) return cache
        while (tmp.length) cache.push(tmp.splice(0, chunkSize))
        return cache
    },

    groupBy: (array, keyFn) => {
        return array.reduce((acc, item) => {
            const key = typeof keyFn === 'function' ? keyFn(item) : item[keyFn];
            if (!acc[key]) {
                acc[key] = [];
            }
            acc[key].push(item);
            return acc;
        }, {});
    }
}
